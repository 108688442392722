import React from "react";
import PropTypes from "prop-types";
import "../styles/styles.scss";
import { I18nProvider } from "@lingui/react";
import catalogEn from "../locales/en/messages.js";
import catalogSv from "../locales/sv/messages.js";
import catalogEt from "../locales/et/messages.js";
import catalogFi from "../locales/fi/messages.js";
import catalogRu from "../locales/ru/messages.js";
import catalogZh from "../locales/zh-hans/messages.js";

const catalogs = {
  en: catalogEn,
  sv: catalogSv,
  et: catalogEt,
  fi: catalogFi,
  ru: catalogRu,
  "zh-hans": catalogZh,
};

const Layout = ({ children, langcode }) => {
  return (
    <I18nProvider language={langcode} catalogs={catalogs}>
      <main>{children}</main>
    </I18nProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
